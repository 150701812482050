import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { H3, H5, NamedLink } from '../../../components';

import css from './NoSearchResultsMaybe.module.css';
import Placeholder from './NoSearchResultPlaceholder';

const NoSearchResultsMaybe = props => {
  const { listingsAreLoaded, totalItems, location, resetAll } = props;
  const hasNoResult = listingsAreLoaded && totalItems === 0;
  const hasSearchParams = location.search?.length > 0;
  return hasNoResult ? (
    <div className={css.noSearchResults}>
      <div className={css.noResults}>
        <FormattedMessage id="SearchPage.noResults" />
        <H5 as="h2">
          <FormattedMessage id="SearchPage.noResultsMessage" />
        </H5>
        {hasSearchParams ? (
          <button className={css.resetAllFiltersButton} onClick={e => resetAll(e)}>
            <FormattedMessage id={'SearchPage.resetAllFilters'} />
          </button>
        ) : null}
        <br />
        <Placeholder />
      </div>

      {/* <p>
        <NamedLink className={css.createListingLink} name="NewListingPage">
          <FormattedMessage id="SearchPage.createListing" />
        </NamedLink>
      </p> */}
    </div>
  ) : null;
};

export default NoSearchResultsMaybe;
