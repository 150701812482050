import React from "react";

function Placeholder() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="258"
            height="382"
            fill="none"
            viewBox="0 0 258 382"
        >
            <g clipPath="url(#clip0_234_356)">
                <path
                    fill="#F7B6A0"
                    d="M166.883 192.989c-2.902-1.443 1.041-10.465 2.294-12.78 10.814-19.956 15.172-34.29 19.474-37.22.621-.897 15.149-5.094 12.64 8.547-.613 4.646-21.177 37.661-25.394 43.853 0 0-3.041 1.1-9.014-2.4z"
                ></path>
                <path
                    fill="#F7B6A0"
                    d="M168.562 194.053c-13.354-7.465-12.96-17.359-23.673-37.038l2.907-2.335c2.925 2.574 22.999 27.016 27.245 27.365 0 0 4.017 12.136-6.479 12.008z"
                ></path>
                <path
                    fill="#fff"
                    d="M179.686 154.042l-10.065 22.456s-3.748 10.016-2.428 16.751c0 0 3.556 4.535 9.491 2.159l25.25-39.6s6.235-18.071-11.075-14.3c0 0-7.386.979-11.173 12.534z"
                ></path>
                <path
                    fill="#F49812"
                    d="M226.969 247.102a18.532 18.532 0 01-17.851-13.493.388.388 0 01.746-.21 17.761 17.761 0 0017.73 12.919c.218-.026.395.159.4.374a.385.385 0 01-.374.4c-.215.008-.436.01-.651.01z"
                ></path>
                <path
                    fill="#122A47"
                    d="M187.395 360.082l1.128 16.746 7.043.082 1.743-16.736-9.914-.092z"
                ></path>
                <path
                    fill="#122A47"
                    d="M198.096 380.145l-31.734.002c.357-3.204 2.846-3.914 2.846-3.914l12.206-2.405 7.142-4.271 2.7 1.294s.541-.097 0 1.226c0 0 3.41.705 5.012-.003l1.831 8.373"
                ></path>
                <path
                    fill="#122A47"
                    d="M198.096 380.145H166.36v1.271h31.736v-1.271zM224.264 353.695l-1.282 16.736-7.045.016-1.589-16.752h9.916z"
                ></path>
                <path
                    fill="#122A47"
                    d="M215.77 368.668l.967-1.492h5.338l1.007 1.928 4.881 8.652v3.879h-14.915v-3.686l2.722-9.281z"
                ></path>
                <path
                    fill="#F49812"
                    d="M233.786 245.879c1.064-4.148.118-15.077-.105-16.195l-2.069-15.788-48.77.495-1.174 19.738.613 11.75h51.505z"
                ></path>
                <path
                    fill="#F49812"
                    d="M231.094 368.496v-83.367l3.363-47.682c-3.104-20.964-34.371-10.612-34.371-10.612l.138 9.632 6.227 58.968 6.597 75.012s12.529 3.077 18.046-1.951zM198.124 220.707c-2.804 0-6.445-.108-12.603-.472a.389.389 0 01-.364-.413.386.386 0 01.41-.364c10.314.608 13.537.5 17.621.364 1.146-.036 2.366-.077 3.83-.105.774-.013 1.789 0 3.007.018 4.41.064 11.791.169 19.718-1.051a.387.387 0 11.118.766c-7.994 1.231-15.413 1.123-19.849 1.057a95.38 95.38 0 00-2.979-.016c-1.458.028-2.674.067-3.82.105-1.664.062-3.184.111-5.089.111z"
                ></path>
                <path
                    fill="#F49812"
                    d="M231.094 237.316c-7.481 0-12.537-3.256-16.39-10.56a.388.388 0 01.687-.362c3.753 7.112 8.45 10.147 15.703 10.147a.387.387 0 010 .775zM181.506 234.945l2.326 135.992s6.099 5.402 15.146.59l6.325-82.247 5.694-55.35-29.421 1.748"
                ></path>
                <path
                    fill="#F49812"
                    d="M207.043 304.113a.39.39 0 01-.387-.361c-1.54-22.991-3.043-34.531-5.312-51.997-.333-2.577-.687-5.292-1.061-8.194-.108-.846-.018-1.779.069-2.682.144-1.497.29-3.045-.454-4.199-.666-1.031-1.694-1.546-3.117-2.256-1.464-.733-3.287-1.643-5.407-3.497a.39.39 0 01-.036-.549.39.39 0 01.549-.036c2.043 1.787 3.815 2.677 5.243 3.39 1.474.738 2.638 1.32 3.42 2.527.892 1.38.73 3.064.574 4.692-.087.903-.169 1.751-.069 2.51.371 2.902.725 5.615 1.061 8.191 2.271 17.482 3.771 29.032 5.315 52.049a.386.386 0 01-.362.412h-.026z"
                ></path>
                <path
                    fill="#192E35"
                    d="M220.442 122.337c.182-.543.343-1.02.471-1.374 2.179-5.948 4.079-20.553-2.91-22.76-8.378-2.646-7.445 10.847-7.729 14.618-.326 4.358-.441 9.04 1.535 13.026.744 1.502 2.521 3.756 4.292 3.52 1.964-.261 3.456-4.435 4.341-7.03z"
                ></path>
                <path
                    fill="#F7B6A0"
                    d="M220.777 117.724c-.064 2.153-1.369 4.54-3.343 5.348-2.679 1.097-3.581-1.356-3.479-3.715.085-1.897 1.062-5.135 3.243-5.715 2.062-.546 3.633 2.315 3.579 4.082z"
                ></path>
                <path
                    fill="#F7B6A0"
                    d="M202.932 120.842l-.781 17.851 14.356.628.78-17.851-14.355-.628z"
                ></path>
                <path
                    fill="#F2937C"
                    d="M212.476 131.153c1.574-2.959 2.566-5.979 2.495-9.793l-12.037-.526-.677 15.518c3.805-.415 8.542-2.048 10.219-5.199z"
                ></path>
                <path
                    fill="#F7B6A0"
                    d="M196.337 140.943l6.274-4.263 13.5.6 7.089 4.855-26.863-1.192z"
                ></path>
                <path
                    fill="#2A9E8C"
                    d="M183.05 185.139c-.526 8.335-1.623 14.754-2.151 23.089-.308 4.912-1.946 21.379-.698 27.647 1.298 6.497 3.966-4.745 9.325-5.82 1.24-.248 2.461-.615 3.704-.843 4.753-.869 7.909 3.538 12.201 5.009 4.194 1.439 8.483 3.549 12.77 4.441 4.553.948 9.819-.082 14.303-1.228 4.522-1.154 5.055-2.418 4.409-6.517-.305-1.933-.238-4.002-.356-5.956l-1.025-17.269c-.454-7.609.22-16.362-1.418-23.825l4.335-30.898c1.31-6.158-4.045-8.414-4.045-8.414l-17.772-8.168c-7.896 5.417-14.38-.043-14.38-.043l-13.508 6.112s-4.405 2.261-5.728 10.17l.034 32.513z"
                ></path>
                <path
                    fill="#192E35"
                    d="M200.486 105.884c-3.564 1.669-2.856 5.122-3.297 8.514-.18 1.394-.657 2.748-.908 4.102-.818-1.434-1.125-6.123-1.174-7.751-.31-10.113 3.099-16.218.551-7.106-.595 2.12 5.697-4.276 7.825-4.825 3.03-.782 2.076 1.869 1.23 5.038l-4.227 2.028z"
                ></path>
                <path
                    fill="#F7B6A0"
                    d="M216.314 122.517c-.967 3.199-2.433 5.879-5.192 7.914-2.702 1.992-5.571 3.138-8.511 1.1-2.341-1.623-3.984-4.138-5.005-6.725-2.35-5.955-1.671-14.536 1.798-19.792 3.809-5.773 12.157-5.268 15.856.498 3.007 4.694 2.618 11.844 1.054 17.005z"
                ></path>
                <path
                    fill="#192E35"
                    d="M215.063 117.098c-.087 2.253-.362 4.086-2.641 5.204-1.782.877-3.54.115-5.181-.641-2.902-1.336-4.207-.259-6.858.472-.285.079-.72.105-.99-.057-1.584-.948-1.443-2.761-3.017-2.487.2 1.849.605 3.628 1.23 5.218 1.021 2.586 2.664 5.104 5.005 6.724 2.943 2.038 5.812.892 8.511-1.1 2.762-2.038 4.225-4.714 5.192-7.914.428-1.42.067-3.292.272-4.92-.09-.499-.575-1.543-1.523-.499z"
                ></path>
                <path
                    fill="#F2937C"
                    d="M218.314 120.09a.386.386 0 01-.364-.253c-.862-2.305-.541-4.584.761-5.42a.39.39 0 01.538.118.389.389 0 01-.115.538c-.964.618-1.164 2.592-.454 4.492a.39.39 0 01-.366.525z"
                ></path>
                <path
                    fill="#F2937C"
                    d="M217.983 116.988a.388.388 0 01-.231-.7c.623-.466 1.643-.366 2.39-.118a.387.387 0 01.246.492.388.388 0 01-.493.247c-.579-.193-1.32-.267-1.679.002a.373.373 0 01-.233.077z"
                ></path>
                <path
                    fill="#192E35"
                    d="M218.003 98.204c.79.033.047 2.825.749 3.291 1.569 1.036.044 2.274.451 3.946.516 2.1.887 4.235 1.162 6.378.107.839 0 2.033.292 2.831-.344-.926-1.733-2.531-3.069-1.118-.556.59-.761 3.325-1.005 4.066-.323.99-.551.636-1.428.71-.31-2.828-.123-10.929-3.153-10.975-3.723-.059-17.526 2.992-17.469-3.638.03-3.728 2.581-8.047 8.411-7.942.003-.003 10.865-1.685 15.059 2.45z"
                ></path>
                <path
                    fill="#F7B6A0"
                    d="M134.918 149.588c-.392.479.444 1.031.787 1.307 2.715 2.205 9.843 7.205 12.488 5.454 3.782-2.505.859-13.288-.31-14.734-1.428.169-.641 4.138-.797 6.358-3.392-2.41-7.004-9.001-8.912-9.563-2.02.554 2.623 4.866 3.792 8.273-4.63-5.068-7.245-7.778-7.827-7.168-1.392.756 3.864 6.917 6.102 8.855-2.859-2.056-4.895-3.833-6.205-4.625-.766-.466-1.243.134-1.243.134-.177 1.428 5.366 5.658 6.973 7.009-1.133-.093-2.225-.967-3.281-1.321-.377-.128-1.126-.387-1.495-.053a1.622 1.622 0 00-.072.074z"
                ></path>
                <path
                    fill="#fff"
                    d="M142.956 159.113s2.235-4.989 6.773-4.656l21.389 22.105s11.447 11.836 5.566 18.846c0 0-11.45 8.675-27.74-23.333l-5.988-12.962z"
                ></path>
                <path
                    fill="#BCDAEB"
                    d="M151.398 155.292l-14.226 11.457-22.54-28.404 14.226-11.454 22.54 28.401z"
                ></path>
                <path
                    fill="#122A47"
                    d="M28.624 16.977C-4.399 43.565-9.611 91.89 16.977 124.909c26.588 33.021 74.915 38.236 107.935 11.645 33.021-26.589 38.233-74.915 11.642-107.933-26.588-33.02-74.912-38.233-107.93-11.644zm89.566 111.229c-28.411 22.876-69.987 18.392-92.863-10.019C2.448 89.779 6.935 48.2 35.347 25.324c28.405-22.876 69.986-18.39 92.86 10.02 22.876 28.408 18.392 69.986-10.017 92.862z"
                ></path>
                <path
                    fill="#2A9E8C"
                    d="M83.849 11.101c36.266 3.915 62.496 36.484 58.578 72.75-3.909 36.267-36.481 62.494-72.742 58.582-36.267-3.915-62.494-36.487-58.582-72.751 3.913-36.266 36.482-62.49 72.746-58.581z"
                    opacity="0.34"
                ></path>
                <path
                    fill="#2A9E8C"
                    d="M142.622 84.462c-3.909 36.266-36.481 62.493-72.743 58.581-36.269-3.912-62.495-36.484-58.58-72.748l131.323 14.167z"
                    opacity="0.45"
                ></path>
                <path
                    fill="#0B2D42"
                    d="M237.334 248.396c3.597 4.469 2.892 11.004-1.572 14.601l-11.252 9.062c-4.466 3.595-11.001 2.892-14.6-1.576l-72.828-90.33c-3.599-4.469-2.889-11.006 1.574-14.601l11.255-9.06c4.463-3.597 11.001-2.892 14.598 1.574l72.825 90.33z"
                ></path>
                <path
                    fill="#464E59"
                    d="M151.8 155.282l85.542 106.12c2.989-3.717 3.107-9.137-.011-13.008l-72.822-90.327c-3.115-3.869-8.437-4.91-12.709-2.785z"
                ></path>
                <path
                    fill="#102D45"
                    d="M229.033 258.474c-4.469 3.597-11.001 2.89-14.598-1.576l-72.828-90.328a10.377 10.377 0 01-1.382-2.279l-1.566 1.261c-4.464 3.595-5.174 10.135-1.574 14.601l72.825 90.327c3.597 4.469 10.134 5.171 14.6 1.577l11.252-9.063c3.754-3.022 4.838-8.116 2.956-12.318l-9.685 7.798z"
                ></path>
                <path
                    fill="#fff"
                    d="M107.83 33.803c1.149 9.983-13.5 19.869-32.728 22.086-19.223 2.213-35.741-4.086-36.887-14.07-1.151-9.983 13.5-19.868 32.723-22.083 19.225-2.213 35.741 4.084 36.892 14.067z"
                ></path>
                <path
                    fill="#F7B6A0"
                    d="M250.399 195.145c-2.815 1.607-8.109-6.694-9.335-9.027-10.547-20.095-18.289-28.329-18.331-33.534-.399-1.015 4.21-15.415 14.142-5.732 3.517 3.097 17.746 35.192 20.538 42.145 0-.003-.779 3.138-7.014 6.148z"
                ></path>
                <path
                    fill="#F7B6A0"
                    d="M255.541 191.011c-6.332 13.926-19.325 23.848-38.048 36.159l-2.569-2.702c2.323-3.128 28.117-34.654 28.114-38.918-.002 0 11.757-5.012 12.503 5.461z"
                ></path>
                <path
                    fill="#F7B6A0"
                    d="M213.045 233.541c3.653-1.821 5.525-5.115 6.433-7.407.643-2.574-.567-3.276-2.105-4.102-1.069-.577-1.728-.477-2.764-.292-.851.274-2.184.313-2.184.313-3.336.353-5.402.553-5.402.553s-2.059.005-2.161.862c-.162 1.371 2.166 1.274 2.166 1.274l1.643.156c.944.057.154.652.154.652l-1.305 1.235c-1.907 2.315-7.401 2.784-7.478 2.677 1.966 2.92 7.671.512 7.671.512s-2.933 1.654-6.812 1.513c0 0-2.497.726.287 1.813 0 0 4.897.63 8.258-1.685 0 0-2.046 2.126-6.381 2.449 0 0-1.451 1.074.364 1.71 0 0 5.786-.982 7.604-2.926 0 0-1.698 1.79-3.946 2.718 0 0-.582 1.028.762 1.118 0 0 3.889-1.641 5.196-3.143z"
                ></path>
                <path
                    fill="#E6F5FE"
                    d="M228.115 141.668s-13.998 3.769-8.097 22.622l20.105 26.209-22.702 27.945s2.057 7.691 7.579 10.698c0 0 35.715-24.868 32.836-41.504l-18.984-39.54s-1.9-3.754-10.737-6.43z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_234_356">
                    <path fill="#fff" d="M0 0H258V381.635H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Placeholder;